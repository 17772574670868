@import 'styles/variables';

.main-page-what-i-can {
  &__labels {
    margin-top: 3rem;

    display: flex;
    flex-wrap: wrap;

    @media all and (max-width: $media-mobile) {
      margin-top: 1.5rem;
    }
  }
}