@import 'styles/variables';
@import 'styles/animations';

.header {
  display: flex;
  margin-left: auto;

  position: absolute;
  right: 5rem;
  top: 4rem;
  z-index: $z-index_2;

  transform: translateX(40%);
  opacity: 0;
  animation: fade-in_horizontal 0.8s ease 1.8s forwards;

  @media all and (max-width: $media-phablet) {
    position: relative;

    top: auto;
    right: auto;

    background-color: $blue;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
    padding: $mobile-layout-content-padding;
    animation-delay: 0.2s;

    transform: translateY(-40%) translateX(0);
  }

  &__logo {
    display: none;

    @media all and (max-width: $media-phablet) {
      display: flex;
    }
  }

  &__separator {
    width: 0.1rem;
    height: 2rem;

    @media all and (max-width: $media-phablet) {
      width: 3rem;
      height: 0.1rem;
    }
  }
}