@keyframes fade-in_vertical {
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes fade-in_horizontal {
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}