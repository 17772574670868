@import './variables.scss';

@mixin cards_layout {
  display: grid;
  grid-gap: 3rem 4rem;
  grid-template-columns: repeat(auto-fill, minmax(28rem, 42rem));
  
  @media all and (max-width: $media-mobile) {
    grid-gap: 1.5rem;
    grid-template-columns: repeat(auto-fill, 35rem);
  }

  @media all and (max-width: $media-mobile_s) {
    grid-template-columns: repeat(auto-fill, 100%);
  }
}

@mixin link {
  color: $blue;
}