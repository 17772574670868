@import 'styles/variables';

.header-menu {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;

  @media all and (max-width: $media-phablet) {
    flex-direction: row;
  }
}