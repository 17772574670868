@import 'styles/variables';

.side-menu-contacts {
  &__list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    color: $white;
  }

  &__item {
    display: flex;
    flex-direction: column;

    &:not(:first-child) {
      margin-top: 2rem;
    }
  }

  &__item-title {
    font-weight: 600;
    font-size: 1.8rem;
    margin-bottom: 0.9rem;
  }

  &__item-contact {
    color: $white;
    font-size: 1.6rem;
  }
}
