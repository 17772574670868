@import 'styles/variables';

.section {
  display: flex;
  flex-direction: column;
  
  &:not(:first-child) {
    margin-top: 8rem;
  }

  @media all and (max-width: $media-mobile) {
    &:not(:first-child) {
      margin-top: 6rem;
    }
  }

  @media all and (max-width: $media-mobile_s) {
    &:not(:first-child) {
      margin-top: 4rem;
    }
  }
}