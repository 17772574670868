@import 'styles/variables';

.card {
  padding: $card-padding;
  border-radius: $card-border-radius;
  transition: $card-transition;
  
  @media all and (max-width: $media-mobile) {
    padding: $mobile-card-padding;
  }

  &_with-hover {
    &:hover {
      box-shadow: $card-box-shadow;
      transition: $card-transition;
    }
  }

  &_hovered {
    box-shadow: $card-box-shadow;
  }

  &_outline {
    border: 1px solid $black;
  }
}