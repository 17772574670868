@import 'styles/variables';
@import 'styles/animations';

.main-page-first-display {
  &__factoids {
    display: grid;
    grid-gap: 4rem 10rem;

    grid-template-columns: 44rem auto;

    @media all and (max-width: $media-mobile) {
      grid-gap: 4rem;
      grid-template-columns: repeat(auto-fit, minmax(auto, 32rem));
    }

    @media all and (max-width: $media-mobile_s) {
      grid-gap: 2.5rem;
    }
  }

  &__title {
    transform: translateY(40%);
    opacity: 0;

    animation: fade-in_vertical 0.8s ease 0.8s forwards;
  }

  &__factoids {
    transform: translateY(40%);
    opacity: 0;

    animation: fade-in_vertical 0.8s ease 1.1s forwards;
  }
}