@import 'styles/variables';
@import 'styles/animations';

.sidebar {
  width: $sidebar-width;
  background-color: $blue;
  color: $white;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: $sidebar-padding;

  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;

  transform: translateX(-50%);
  opacity: 0;
  animation: fade-in_horizontal 0.8s ease 0.2s forwards;

  @media all and (max-width: $media-phablet) {
    display: none;
  }
};