@import 'styles/variables';

.language-switcher {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 2.2rem;
  line-height: normal;
  color: $black;
  font-weight: 600;
  cursor: pointer;

  @media all and (max-width: $media-phablet) {
    color: $white;
  }
}