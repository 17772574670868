// colors
$white: #ffffff;
$black: #282828;
$blue: #4622F4;

// z-index
$z-index_1: 1;
$z-index_2: 2;
$z-index_3: 3;
$z-index_4: 4;
$z-index_5: 5;

// for media queries
$media-phablet: 1200px;
$media-phablet_s: 980px;
$media-mobile: 850px;
$media-mobile_s: 450px;

// some components fixed width
$sidebar-width: 30rem;

// common variables
$layout-content-padding: 4rem 10rem 4rem 5rem;
$phablet-layout-content-padding: 4rem;
$mobile-layout-content-padding: 2.5rem 2rem;

// sidebar variables
$sidebar-padding: 4rem 4rem;

// headings variables
$h1-size: 5rem;
$h2-size: 4rem;
$h3-size: 3rem;
$h4-size: 2.6rem;
$h5-size: 2.2rem;

$mobile-h1-size: 4rem;
$mobile-h2-size: 3rem;
$mobile-h3-size: 2.5rem;
$mobile-h4-size: 2rem;
$mobile-h5-size: 1.8rem;

$mobile-h1-size_s: 3rem;
$mobile-h2-size_s: 2.4rem;
$mobile-h3-size_s: 2rem;
$mobile-h4-size_s: 1.7rem;
$mobile-h5-size_s: 1.5rem;

// paragraphs variables
$p-size: 2.2rem;
$mobile-p-size: 1.8rem;
$mobile-p-size_s: 1.5rem;

// card variables
$card-border-radius: 3px;
$card-padding: 3rem;
$mobile-card-padding: 1.5rem 2rem;
$card-box-shadow: 5px 5px 30px 3px rgba($black, 0.12);
$card-transition: all 0.3s ease;