@import 'styles/variables';

.socials {
  display: flex;

  &__item {
    &:not(:first-child) {
      margin-left: 2rem;
    }
  }

  &__item-icon {
    width: 2.4rem;
    height: 2.4rem;

    &_theme {
      &_light {
        fill: $white;
      }
      &_dark {
        fill: $black;
      }
    }
  }
}