@import 'styles/variables';

.label {
  display: inline-block;
  border: 1px solid $black;
  color: $black;
  font-size: $p-size;
  border-radius: 3px;
  padding: 1.2rem 2.6rem;
  transition: all 0.25s ease-out;

  margin: 0 2.5rem 2.5rem 0;

  &:hover {
    border-color: $blue;
    color: $blue; 
    transition: all 0.25s ease-in;
  }

  @media all and (max-width: $media-mobile) {
    font-size: $mobile-p-size;
    padding: 0.6rem 1.6rem 0.8rem;

    margin: 0 1rem 1rem 0;

    &:hover {
      border-color: $black;
      color: $black;
    }
  }
}