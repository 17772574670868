@import 'styles/variables';

.footer {
  display: flex;
  padding: $layout-content-padding;
  
  @media all and (max-width: $media-phablet) {
    padding: $phablet-layout-content-padding;
  }
  
  @media all and (max-width: $media-mobile) {
    padding: $mobile-layout-content-padding;
  }
  
  &__requisites {
    color: rgba($black, 0.5);
    font-size: 1.5rem;
  }
}