@import 'styles/variables';

.logo {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-weight: 600;
  font-size: 3.4rem;
  line-height: normal;
  color: $white;
  text-decoration: none;

  @media all and (max-width: $media-phablet) {
    font-size: 2rem;
    display: flex;
  }

  &__firstname {
    margin-left: 2rem;

    @media all and (max-width: $media-phablet) {
      margin-left: 0.5rem;
    }
  }
}
