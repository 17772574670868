@import 'styles/variables';

.layout {
  width: 100%;
  min-height: 100%;
  display: block;
  position: relative;

  padding-left: $sidebar-width;

  @media all and (max-width: $media-phablet) {
    padding-left: 0;
  }

  &__content {
    display: flex;
    flex-direction: column;

    width: 100%;
    min-height: 100vh;

    padding: $layout-content-padding;

    @media all and (max-width: $media-phablet) {
      padding: $phablet-layout-content-padding;
    }

    @media all and (max-width: $media-mobile) {
      padding: $mobile-layout-content-padding;
    }
  }
}