@import 'styles/variables';
@import 'styles/animations';

.main-page-about {
  display: flex;
  align-items: center;

  z-index: 2;
  position: relative;

  animation: fade-in_vertical 0.8s ease 1.4s forwards;
  transform: translateY(40%);
  opacity: 0;
  
  @media all and (max-width: $media-mobile) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  &__img {
    width: 100%;
    max-width: 35rem;
    box-shadow: 5px 5px 30px 3px rgba($black, 0.3);

    position: relative;
    z-index: $z-index_2;

    transform: translateX(-10rem);
    
    @media all and (max-width: $media-phablet) {
      transform: none;
    }

    @media all and (max-width: $media-phablet_s) {
      max-width: 30rem;
    }

    @media all and (max-width: $media-mobile) {
      margin-top: 3rem;
    }
  }

  &__title {
    color: $blue;
  }

  &__content {
    width: 100%;
    max-width: 55rem;
    position: relative;
    margin-left: 3rem;

    @media all and (max-width: $media-phablet) {
      margin-left: 14rem;
    }

    @media all and (max-width: $media-phablet_s) {
      margin-left: 10rem;
      max-width: 100%;
    }

    @media all and (max-width: $media-mobile) {
      margin-left: 0;
      margin-right: 4rem;
      max-width: 44rem;
    }

    &::before {
      content: '';
      display: block;
      height: 0.5rem;
      width: 14.5rem;
      background-color: $blue;
      position: absolute;
      z-index: $z-index_3;
      top: 9.1rem;
      left: -4rem;
      transform: translateX(-100%);

      @media all and (max-width: $media-phablet_s) {
        width: 10rem;
      }

      @media all and (max-width: $media-mobile) {
        display: none;
      }
    }
  }
}
