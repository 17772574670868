@import "./fonts";
@import "./variables";

html {
  box-sizing: border-box;
  height: 100%;
  font-size: 10px;
  overflow-anchor: none;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background: $white;
}

h1, h2, h3, h4, h5 {
  font-family: 'Fira Sans Condensed', sans-serif;
  color: $black;
  font-weight: 600;
  margin: 0;
}

h1 {
  font-size: $h1-size;
  margin-bottom: 5rem;

  @media all and (max-width: $media-mobile) {
    font-size: $mobile-h1-size;
    margin-bottom: 3rem;
  }

  @media all and (max-width: $media-mobile_s) {
    font-size: $mobile-h1-size_s;
  }
}

h2 {
  font-size: $h2-size;
  margin-bottom: 3rem;

  @media all and (max-width: $media-mobile) {
    font-size: $mobile-h2-size;
    margin-bottom: 1.5rem;
  }

  @media all and (max-width: $media-mobile_s) {
    font-size: $mobile-h2-size_s;
  }
}

h3 {
  font-size: $h3-size;
  margin-bottom: 1rem;

  @media all and (max-width: $media-mobile) {
    font-size: $mobile-h3-size;
    margin-bottom: 0.6rem;
  }

  @media all and (max-width: $media-mobile_s) {
    font-size: $mobile-h3-size_s;
  }
}

h4 {
  font-size: $h4-size;
  margin-bottom: 0.6rem;

  @media all and (max-width: $media-mobile) {
    font-size: $mobile-h4-size;
  }

  @media all and (max-width: $media-mobile_s) {
    font-size: $mobile-h4-size_s;
  }
}

h5 {
  font-size: $h5-size;
  margin-bottom: 0.6rem;

  @media all and (max-width: $media-mobile) {
    font-size: $mobile-h5-size;
  }

  @media all and (max-width: $media-mobile_s) {
    font-size: $mobile-h5-size_s;
  }
}

p {
  margin: 0;
  color: $black;
  font-size: $p-size;

  @media all and (max-width: $media-mobile) {
    font-size: $mobile-p-size;
  }

  @media all and (max-width: $media-mobile_s) {
    font-size: $mobile-p-size_s;
  }
}

.link {
  color: $blue;
}